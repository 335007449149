//Variaveis
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&display=swap");
// Include dos asquivos SCSS
@import "_color";
@import "_mixings";
@import "_helpers";
@import "_popUp";
@import "_header";
@import "_footer";
@import "_main";
@import "_login";
@import "_aux";
@import "_cookieConsent";

body {
  font-family: "Ubuntu", sans-serif;
  color: #333;
}
