.login-area{
	position: relative;
	.login-form{
		position: absolute;
		max-width: 440px;
		max-height: 380px;
		width: 100%;
		height: 100%;
		text-align: center;
		background: white;
		padding: 40px 40px;
		box-shadow: 0 3px 7px #222;
		h1{
			margin: 0;
			font-size: 35px;
			color: $secundaria;
			margin-bottom: 25px;
			font-weight: 600;
		}
		input{
			display: inline-block;
			background: $secundaria;
			border: none;
			margin: 10px 0;
			padding: 12px 7px;
			width: 100%;
			color: #FFF;
			text-align: center;
			&::placeholder{
				color: #FFF;
			}
			&:focus{
				outline: none;
				box-shadow: 0px 0px 5px $primaria;
			}
		}
		.btn-submit{
			background: $secundaria;
			color: white;
			width: 100%;
			border-radius: 0;
			text-transform: uppercase;
			font-size: 13px;
			padding: 10px 0;
			margin-top: 10px;
		}
		.left{
			text-align: left;
		}
		.right{
			text-align: right;
		}
		.btn-senha{
			color: $secundaria;
			border-radius: 0;
			text-transform: uppercase;
			font-size: 13px;
			padding: 10px 0;
			margin-top: 10px;
			text-decoration: none;
			display: inline-block;
			&:hover{
				color: $secundaria;
			}
		}
	}
}