header {
  width: 100%;
  z-index: 10;
  position: static;
  top: 0;
  .pre-header {
    width: 100%;
    position: relative;
    .black-area {
      padding: 12px 0;
      background: #555;
      width: 30%;
      height: 100%;
      position: absolute;
      border-radius: 0 15px 0 0;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: right;
        padding-right: 15px;
        a {
          text-decoration: none;
          display: inline-block;
          margin: 0 15px;
          li {
            display: inline-block;
            color: #bbb;
          }
        }
      }
      .triangulo {
        position: absolute;
        bottom: 0;
        left: calc(100% - 5px);
        border-top: 40px solid transparent;
        border-left: 40px solid #555;
      }
    }
    .green-area {
      background-image: linear-gradient(to right, $secundaria, $primaria);
      width: 100%;
      padding: 12px 0;
      vertical-align: middle;
      p {
        text-align: right;
        margin: 0;
        padding-right: 100px;
        color: white;
      }
    }
  }
  .menu {
    background: white;
    box-shadow: 0px 3px 5px rgba(black, 0.25);
    padding: 15px 0;
    min-height: 100px;
    z-index: 10;
    .logo {
      z-index: 10;
      display: inline-block;
      padding: 32px 0;
      img {
        width: 100%;
        min-width: 110px;
        max-width: 200px;
      }
    }
    .header-menu {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: right;
      width: 100%;
      display: table;
      a {
        text-decoration: none;
        display: inline-block;
        padding: 40px 0;
        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          color: #666;
          padding: 0 7px;
        }

        &:hover {
          li {
            color: $secundaria;
          }
        }
        &.active {
          background: #f7f7f7;
          li {
            color: $secundaria;
            &:after {
              content: " ";
              background: $secundaria;
              width: calc(100% - 16px);
              height: 3px;
              @include alignAbsolute(100%, 50%, translate(-50%, 52px));
            }
          }
        }
      }
    }
    .responsive-button {
      font-size: 18px;
      text-decoration: none;
      color: #333;
      display: none;
      position: absolute;
      right: 25px;
      top: 0;
      transform: translateY(20px);
      z-index: 25;
    }
    .responsive-menu {
      position: absolute;
      top: 50px;
      left: calc(100% - 120px);
      list-style: none;
      z-index: 25;
      padding: 0;
      margin: 0;
      text-align: center;
      min-width: 120px;
      display: none;
      a {
        background: white;
        box-shadow: 0px 2px 4px rgba(black, 0.5);
        position: relative;
        display: block;
        margin-top: 15px;
        padding: 10px 0;
        width: 100%;
        text-decoration: none;
        color: black;
        border-radius: 2px;
        li {
          display: inline-block;
          width: 100%;
        }
        &.active {
          border-bottom: 2px solid $secundaria;
          color: $secundaria;
        }
      }
    }
  }
}
