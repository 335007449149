.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99997;

  background-color: white;
  padding: 2rem;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.25);

  > h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .cookie-consent-content {
    display: flex;
    gap: 1rem;
  }
}

#cookiesModal {
  display: none;
  position: fixed;
  z-index: 99998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);

  .modal-header {
    display: flex !important;
    justify-content: space-between !important;

    &::before, &::after {
      content: '';
      display: none;
    }

    h5 {
      font-size: 1.5rem;
    }
  }

  .cookie-types {
    margin-top: 2.5rem;
  }

  h6 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .cookies-modal-buttons-group {
    margin-top: 2rem;
  }
}