.relative{
	position: relative;
}

.p70{
	padding: 70px 0;
}

.p10{
	padding: 10px 0;
}

.mb60{
	margin-bottom: 60px;
}

.mb30{
	margin-bottom: 30px;
}

.mb15{
	margin-bottom: 15px;
}

.align-right{
	text-align: right;
}

.align-center{
	text-align: center;
}