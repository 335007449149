.ver {
  display: block !important;
}
.link_rodape {
  font-size: 20px;
  font-weight: bold;
  a {
    text-decoration: none;
    color: #fff !important;
  }
}

.btn-blue {
  background:$secundaria;
  color: #fff !important;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 30px;
  margin-right: 10px;
  border-radius: 30px;
}

@media (max-width: 1199px) {
  header {
    .menu {
      .logo {
        img {
          width: 100%;
          min-width: 110px;
          max-width: 150px;
        }
      }
      .header-menu {
        a {
          display: table-cell;
          li {
            font-size: 15px;
          }
        }
      }
    }
  }
  main {
    #faq {
      .faqs {
        .faq {
          .title {
            border: none;
            text-align: left;
            h1 {
              font-size: 16px;
              .fa {
                font-size: 18px;
              }
            }
          }
          .text {
            display: none;
            p {
              color: black;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  header {
    .menu {
      .responsive-button {
        display: block;
      }
    }
  }
  main {
    #capa {
      .mensagem {
        display: none;
      }
      .cards {
        .card {
          margin-top: 15px;
        }
      }
    }
    #faq {
      .faqs {
        .faq {
          .title {
            h1 {
              font-size: 18px;
              .fa {
                font-size: 20px;
              }
            }
          }
          .text {
            display: none;
            p {
              color: black;
              font-size: 16px;
            }
          }
        }
      }
    }
    #parceiros {
      .title {
        text-align: center;
      }
    }
  }
}
@media (max-width: 768px) {
  header {
    .pre-header {
      .green-area {
        p {
          padding-right: 15px;
        }
      }
    }
    .menu {
      .responsive-menu {
        left: calc(100% - 135px);
      }
    }
  }
  main {
    #infos {
      .col-esq {
        margin-bottom: 4vh;
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
          span{
            font-size: 16px;
          }
        }
        .btn-blue {
          font-size: 14px;
          padding: 7px 12px;
          margin-bottom: 10px;
        }
      }
      .col-dir {
        .content {
          &#postos {
            h1 {
              font-size: 25px;
            }
            p {
              font-size: 16px;
            }
            ul {
              li {
                font-size: 14px;
                span{
                  font-size: 14px;
                }
              }
            }
          }
          &#trabalhe {
            h1 {
              font-size: 25px;
            }
            p {
              font-size: 16px;
            }
            form {
              h1 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .space{
      margin-bottom: 4vh;
    }
    #parceiros {
      .slide-area {
        .slide-parceiros {
          padding: 0 7px;
        }
        .prev {
          transform: translate(calc(-100% + 5px), -50%);
        }
        .next {
          transform: translate(calc(100% - 5px), -50%);
        }
      }
    }
    #institucional {
      .texto {
        columns: 1;
      }
    }
  }
  footer {
    .row {
      text-align: center;
    }
  }
}
@media (max-width: 560px) {
  header {
    .pre-header {
      .black-area {
        .triangulo {
          border-top: 37px solid transparent;
          border-left: 37px solid #555;
        }
      }
      .green-area {
        p {
          font-size: 12px;
        }
      }
    }
  }
  main {
    #faq {
      .faqs {
        .faq {
          .title {
            text-overflow: ellipsis;
            h1 {
              font-size: 14px;
              .fa {
                font-size: 16px;
              }
            }
          }
          .text {
            display: none;
            p {
              color: black;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  footer {
    .row {
      text-align: left;
    }
    .col-xs-6 {
      text-align: center;
    }
  }
  main {
    #contato {
      .fale-conosco {
        padding: 0 0 10px;
      }
    }
  }
}
@media (max-width: 480px) {
  header {
    .pre-header {
      .black-area {
        width: 30%;
        ul {
          padding-right: 15px;
          a {
            margin: 0 5px;
          }
        }
        .triangulo {
          border-top: 32px solid transparent;
          border-left: 32px solid #555;
        }
      }
      .green-area {
        p {
          padding-right: 5px;
          font-size: 9px;
        }
      }
    }
  }
}
@media (max-width: 440px) {
  main {
    #faq {
      .faqs {
        .faq {
          .title {
            h1 {
              position: relative;
              padding-right: 15px;
              .fa {
                float: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
  .login-area {
    .login-form {
      padding: 40px 20px;
    }
  }
}

@media (max-width: 425px) {
  header {
    .pre-header {
      .green-area {
        p {
          padding-right: 15px;
        }
      }
    }
    .menu {
      .responsive-menu {
        left: calc(100% - 135px);
      }
    }
  }
  main {
    #infos {
      .col-esq {
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
          span{
            font-size: 16px;
          }
        }
        .btn-blue {
          font-size: 14px;
          padding: 7px 12px;
          margin-bottom: 10px;
        }
      }
      .col-dir {
        .content {
          &#postos {
            h1 {
              font-size: 25px;
            }
            p {
              font-size: 16px;
            }
            ul {
              li {
                font-size: 13px;
                span{
                  display: block;
                  float: initial;
                  font-size: 12px;
                }
              }
            }
          }
          &#trabalhe {
            h1 {
              font-size: 25px;
            }
            p {
              font-size: 16px;
            }
            form {
              h1 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    #parceiros {
      .slide-area {
        .slide-parceiros {
          padding: 0 7px;
        }
        .prev {
          transform: translate(calc(-100% + 5px), -50%);
        }
        .next {
          transform: translate(calc(100% - 5px), -50%);
        }
      }
    }
    #institucional {
      .texto {
        columns: 1;
      }
    }
  }
  footer {
    .row {
      text-align: center;
    }
  }
}
