footer {
  background: #333;
  .title {
    margin-bottom: 50px;
    h1 {
      display: inline-block;
      color: white;
      text-transform: uppercase;
      font-size: 22px;
      font-weight: 400;
      position: relative;
      margin: 0;
      &:before {
        content: " ";
        background: $secundaria;
        height: 1px;
        width: 60px;
        position: absolute;
        @include alignAbsolute(calc(100% + 10px), 0, translate(0, 0));
      }
    }
  }
  p {
    color: white;
    margin: 0;
    font-size: 13px;
    &.mensagem {
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    a {
      text-decoration: none !important;
    }
    li {
      color: white;
      margin-bottom: 15px;
    }
  }
  form {
    input,
    textarea {
      width: 100%;
      background: transparent;
      border: 1px solid #777;
      color: #777;
      border-radius: 5px;
      padding: 7px 5px;
      margin-bottom: 10px;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    .btn {
      background: #777;
      color: white;
      &:hover {
        color: white;
        background: #666;
      }
    }
  }
  .pos-footer {
    border-top: 1px solid #777;
    width: 100%;
    p,
    a {
      font-size: 14px;
      display: inline-block;
      text-decoration: none;
      color: white;
    }
  }
}


// LOGO ANIMADA NVGO
.cls-1 {
  fill: #3aadd4;
}
.cls-2 {
  fill: #fbbc05;
}
.cls-3 {
  fill: #fe6353;
}
.cls-4,
.cls-5 {
  fill: #fff;
}
.cls-5 {
  font-size: 50px;
  font-family: Lato-Regular, Lato;
}

.nvlogo{
	display: flex;
	height: 100%;
	@media only screen and (max-width: 768px){
		justify-content: center;
	}
	svg{
    height: 8vh;
	}
	#arrow-left,#arrow-right,#tipo{
		transition:1.8s;
	}
	&:hover{
		#tipo{
			opacity:0;
		}
		#arrow-right{
			transform:translateX(-90px) translateY(20px);
		}
		#arrow-left{
			transform:translateX(90px);
		}
	}
}
