main {
  #capa {
    position: relative;
    min-height: 800px;
    width: 100%;
    .bg {
      width: 100%;
      position: relative;
      overflow: hidden;
      height: 800px;
    }
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: rgba(white, 0.5);
    }
    .mensagem {
      position: absolute;
      width: 100%;
      @include alignAbsolute(50%, 0, translate(0, -100%));
      z-index: 10;
      h1 {
        text-transform: uppercase;
        font-size: 55px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
        .blue {
          color: $primaria;
          font-weight: 400;
        }
      }
      p {
        margin: 0;
        margin-bottom: 15px;
        font-size: 17px;
      }
      .btn-black {
        background: $primaria;
        color: white;
        text-decoration: none;
        padding: 10px 0px;
        text-align: center;
        min-width: 200px;
        border-radius: 30px;
        transition: 0.3s;
        &:hover {
          background: #222;
        }
      }
    }
  }
  .cards {
    position: relative;
    width: 100%;
    z-index: 10;
    padding: 150px 0;
    padding-bottom: 350px;
    margin-top: -100px;
    background-image: url("/2018/dist/img/DNA.jpg");
    background-attachment: fixed;
    &::before {
      background-image: linear-gradient(
        to bottom right,
        rgba(196, 61, 142, 1),
        transparent,
        rgba(39, 73, 159, 1)
      );
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &::after {
      background-image: url("/2018/dist/img/bg2.png");
      content: "";
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
    }
    .card {
      border-bottom: 10px solid $primaria;
      background: white;
      border-radius: 7px;
      max-height: 200px;
      &.no-header {
        .card-body {
          .content {
            display: table;
            padding-bottom: 10px;
            figure {
              display: table-cell;
              height: 100%;
              text-align: center;
              width: 35%;
              padding: 40px 0;
              .fa {
                font-size: 70px;
                display: inline-block;
              }
            }
            figcaption {
              display: table-cell;
              vertical-align: middle;
              width: 65%;
              padding: 40px 0;
              h1 {
                font-size: 24px;
                margin: 0;
                margin-bottom: 10px;
                font-weight: 300;
              }
              p {
                font-size: 15px;
              }
            }
          }
          form {
            padding: 30px 0;
            text-align: center;
            height: 100%;
            h1 {
              margin: 0;
              font-size: 25px;
              color: $secundaria;
            }
            input {
              width: 60%;
              display: inline-block;
              background: $secundaria;
              border: none;
              margin: 10px 0;
              padding: 12px 17px;
              color: #bbb;
              &::placeholder {
                color: #bbb;
              }
            }
          }
        }
      }
      &.green {
        background: $primaria;
        color: #fff;
      }
    }
  }
  #infos {
    background: white;
    .col-esq {
      h1 {
        margin: 0;
        font-family: "Ubuntu", sans-serif;
        font-size: 28px;
      }
      p {
        margin-bottom: 25px;
        font-family: "Ubuntu", sans-serif;
        font-size: 18px;
        p {
          margin: 0;
        }
        span {
          font-family: "Ubuntu", sans-serif;
          font-size: 18px;
        }
      }
      .green {
        color: $primaria;
      }
      .blue {
        color: $secundaria;
      }
      .btn-blue {
        background: $primaria;
        color: white;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 16px;
        padding: 15px 30px;
        margin-right: 10px;
        transition: 0.3s;
        border-radius: 30px;
        &:hover {
          background: $secundaria;
        }
        &.active {
          background: $secundaria;
        }
      }
    }
    .col-dir {
      .content {
        display: none;
        &#postos {
          display: block;
          margin-bottom: 0;
          h1 {
            margin: 0;
            font-size: 28px;
            font-weight: 300;
            margin-bottom: 5px;
          }
          p {
            margin-bottom: 25px;
            font-size: 18px;
            color: #bbb;
            font-weight: 600;
          }
          ul {
            list-style: none;
            padding: 0;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: #666;
            li {
              align-items: center;
              border-top: none;
              display: flex;
              justify-content: space-between;
              padding: 20px;
              font-size: 16px;
              font-weight: normal;
              i {
                margin-right: 0.2vw;
              }
              span {
                font-size: 15px;
                font-weight: 300;
              }
              a {
                margin-right: 14px;
                &:hover {
                  color: #222;
                }
              }
            }
          }
        }
        &#trabalhe {
          h1 {
            margin: 0;
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 5px;
          }
          p {
            margin-bottom: 25px;
            font-size: 18px;
            color: #bbb;
            font-weight: 600;
          }
          form {
            height: 100%;
            h1 {
              margin: 0;
              font-size: 25px;
              color: $secundaria;
            }
            input {
              width: 100%;
              display: inline-block;
              background: $secundaria;
              border: none;
              margin: 10px 0;
              padding: 12px 17px;
              color: #bbb;
              &::placeholder {
                color: #bbb;
              }
              &[type="file"] {
                display: none;
              }
            }
            label {
              width: 100%;
              display: inline-block;
              background: $secundaria;
              border: none;
              margin: 10px 0;
              padding: 12px 17px;
              color: #bbb;
              font-weight: 300;
            }
            .btn-submit {
              background: $secundaria;
              color: white;
              border-radius: 0;
              text-transform: uppercase;
              font-size: 13px;
              padding: 10px 0;
              display: block;
              width: 100%;
              margin-top: 10px;
              &:hover {
                background: $primaria;
              }
            }
          }
        }
      }
    }
  }
  .blue-bg {
    background-image: url("/2018/dist/img/depoimento.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 350px 0;
    position: relative;
    .bg {
      background-image: linear-gradient(
        to bottom right,
        rgba($primaria, 0.8),
        rgba($secundaria, 0.8)
      );
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &::after {
      background-image: url("/2018/dist/img/bg2.png");
      content: "";
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 300px;
    }
    &::before {
      background-image: url("/2018/dist/img/bg2.png");
      content: "";
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 300px;
      transform: scale(-1, -1);
      z-index: 9999;
    }
    .custom-list {
      background: white;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      border-radius: 5px;
      max-width: 400px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

      li {
        padding: 15px 30px;
        text-transform: uppercase;
        font-size: 15px;
        position: relative;
        transition: 0.3s;
        &:not(.align-right):hover {
          background: $secundaria;
          color: white;
          border-color: $secundaria;
          &:after {
            content: " ";
            position: absolute;
            @include alignAbsolute(50%, 100%, translate(0, -50%));
            border-left: 8px solid $secundaria;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
          }
        }
        &.align-right {
          padding: 5px 30px;
          &:hover {
            background: white;
            color: black;
            border-color: $secundaria;
          }
        }
        .btn-custom-list {
          background-color: $primaria;
          text-decoration: none;
          color: white;
          font-size: 13px;
          padding: 10px 20px;
          border-radius: 20px;
          transition: 0.15s;
          transition: 0.3s;
          margin: 5px 0;
        }
      }
    }
    .fale-conosco {
      height: 100%;
      background: white;
      padding: 40px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

      h1 {
        margin: 0;
        font-size: 32px;
        color: $secundaria;
        margin-bottom: 15px;
      }
      p {
        color: #999;
        font-size: 18px;
      }
      input,
      textarea {
        width: 100%;
        display: inline-block;
        border: none;
        margin: 10px 0;
        padding: 12px 17px;
        color: #bbb;
        resize: none;
        border: 1px solid #d1d1d1;
        &::placeholder {
          color: #bbb;
        }
        &[type="file"] {
          display: none;
        }
      }
      label {
        width: 100%;
        display: inline-block;
        background: $secundaria;
        border: none;
        margin: 10px 0;
        padding: 12px 17px;
        color: #bbb;
        font-weight: 300;
      }
      .btn-submit {
        background: $primaria;
        color: white;
        width: 100%;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 13px;
        padding: 15px 0;
        margin-top: 10px;
        border-radius: 30px;
      }
    }
  }
  #certificados {
    background: white;
    .title {
      border-bottom: 1px solid #ddd;
    }
    .lista-certificados {
      border-bottom: 1px solid #ddd;
      padding: 50px 0;
      text-align: center;
      img {
        width: 100%;
        max-width: 200px;
        margin-bottom: 30px;
      }
    }
  }
  .title {
    text-align: center;
    h1 {
      color: $secundaria;
    }
    p {
      font-size: 18px;
      color: #999;
      font-weight: 600;
    }
  }
  #faq {
    border-bottom: 1px solid #ddd;
    h1 {
      margin: 0;
      font-size: 32px;
      color: black;
      margin-bottom: 15px;
    }
    p {
      color: #999;
      font-size: 18px;
    }
    .card {
      border: 1px solid #bbb;
      margin: auto;
      margin-bottom: 15px;
      max-width: 300px;
      width: 100%;
      .card-header {
        position: relative;
        min-height: 280px;
        overflow: hidden;
        img {
          position: relative;
          width: 100%;
        }
      }
      .card-body {
        border-bottom: 3px solid $secundaria;
        text-align: center;
        padding: 15px 0;
        h1 {
          font-size: 22px;
          margin: 0;
          margin-bottom: 5px;
        }
        p {
          margin: 0;
          font-size: 15px;
          font-style: italic;
        }
      }
      &:hover {
        .card-body {
          background: $secundaria;
          h1 {
            color: white;
          }
        }
      }
    }
    .faqs {
      .faq {
        border: 1px solid #bbb;
        padding: 15px;
        border-radius: 25px;
        cursor: pointer;
        margin-bottom: 20px;
        &:hover {
          background: $secundaria;
          .title {
            h1 {
              color: white;
            }
          }
        }
        .title {
          border: none;
          text-align: left;
          h1 {
            font-size: 18px;
            margin: 0;
            .fa {
              font-size: 20px;
              float: right;
            }
          }
        }
        .text {
          display: none;
          p {
            color: black;
          }
        }
        &.active {
          padding: 0;
          border: none;
          &:hover {
            background: white;
          }
          .title {
            background: $secundaria;
            border-radius: 20px 20px 0 0;
            padding: 15px;
            h1 {
              color: white;
            }
          }
          .text {
            display: block;
            padding: 5px 15px;
            border: 1px solid #bbb;
            border-radius: 0 0 20px 20px;
          }
        }
      }
    }
  }
  #parceiros {
    .title {
      border: none;
      text-align: left;
    }
    .slide-area {
      position: relative;
      .slide-parceiros {
        margin: 0;
        .slide-item {
          display: inline-block;
          padding: 0 15px;
          width: calc((100% / 3) - 30px);
          img {
            width: 100%;
          }
        }
      }
      .prev,
      .next {
        position: absolute;
        top: 50%;
        background: #bbb;
        border: none;
        border-radius: 5px;
        min-width: 15px;
        text-align: center;
        color: white;
        font-style: 12px;
      }
      .prev {
        left: 0;
        transform: translate(calc(-100% - 5px), -50%);
      }
      .next {
        right: 0;
        transform: translate(calc(100% + 5px), -50%);
      }
    }
  }
  #institucional {
    .title {
      border: none;
      p {
        .fa {
          font-size: 70px;
          margin: 0 15px;
        }
        .fa-cc-diners-club {
          color: blue;
        }
        .fa-cc-visa {
          color: blue;
        }
        .fa-cc-mastercard {
          color: red;
        }
      }
    }
    .texto {
      display: table-cell;
      columns: 2;
      text-align: justify;
      font-size: 15px;
      p {
        padding: 0 15px;
        margin-bottom: 25px;
      }
    }
    .d-table {
      display: table;
    }
    img {
      width: 100% !important;
      height: auto !important;
      margin: auto;
      display: block;
    }
  }
  #convenios {
    .title {
      border: none;
      p {
        .fa {
          font-size: 70px;
          margin: 0 15px;
        }
        .fa-cc-diners-club {
          color: blue;
        }
        .fa-cc-visa {
          color: blue;
        }
        .fa-cc-mastercard {
          color: red;
        }
      }
    }
    .texto {
      display: table-cell;
      columns: 2;
      text-align: justify;
      font-size: 15px;
      p {
        padding: 0 15px;
        margin-bottom: 25px;
      }
    }
    .d-table {
      display: table;
    }
    .img-convenio {
      width: calc(100% / 4);
      display: inline-block;
      img {
        display: inline-block;
      }
    }
    img {
      width: 100%;
      max-width: 400px;
      margin: auto;
      display: block;
      transform: scale(0.7, 0.7);
    }
  }
  #exames,
  #contato,
  #postos {
    .card {
      border: 1px solid $secundaria;
      border-bottom: 5px solid $secundaria !important;
      background: white;
      border-radius: 7px;
      &.no-border-bottom {
        border-bottom: 1px solid $secundaria;
      }
      &.no-header {
        .card-body {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding: 10px;
            border-bottom: 1px solid $secundaria;
            &:last-child {
              border-bottom: none;
            }
          }
          .titulo {
            border-radius: 7px 7px 0 0;
            background: $secundaria;
            color: white;
            font-weight: 600;
          }
        }
      }
    }
    .card-exames {
      margin-bottom: 10px;
      .card-body {
        .titulo {
          cursor: pointer;
        }
        .desc {
          display: none;
        }
      }
    }
    .fale-conosco {
      height: 100%;
      padding: 0 0 10px;
      text-align: center;
      border-radius: 7px;
      h1 {
        margin: 0;
        font-size: 32px;
        color: $secundaria;
        margin-bottom: 15px;
      }
      p {
        color: #999;
        font-size: 18px;
      }
      input,
      textarea {
        width: 100%;
        display: inline-block;
        background: $secundaria;
        border: none;
        margin: 10px 0;
        padding: 12px 17px;
        color: #bbb;
        resize: none;
        &::placeholder {
          color: #bbb;
        }
        &[type="file"] {
          display: none;
        }
      }
      label {
        width: 100%;
        display: inline-block;
        background: $secundaria;
        border: none;
        margin: 10px 0;
        padding: 12px 17px;
        color: #bbb;
        font-weight: 300;
        text-align: left;
      }
      .btn-submit {
        background: $secundaria;
        color: white;
        width: 100%;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 0;
        margin-top: 10px;
        &:hover {
          background: $secundaria;
        }
      }
    }
    iframe {
      width: 100%;
      min-height: 350px;
      margin-top: 15px;
    }
  }
  #equipe {
    .card {
      float: left;
      margin-bottom: 20px;
      .card-header {
        border: 1px solid #bbb;
        border-bottom: none;
        position: relative;
        min-height: 280px;
        overflow: hidden;
        img {
          position: absolute;
          width: 100%;
          @include alignAbsolute(50%, 50%, translate(-50%, -50%));
        }
      }
      .card-body {
        border-left: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-bottom: 3px solid $secundaria;
        text-align: center;
        padding: 15px;
        h1 {
          font-size: 22px;
          margin: 0;
          margin-bottom: 5px;
        }
        .sub-t {
          margin: 0;
          font-size: 15px;
          font-style: italic;
          margin-bottom: 15px;
          text-align: center;
        }
        .info {
          max-height: 250px;
          overflow: scroll;
          padding-right: 30px;
        }
        .link {
          margin-top: 10px;
        }
        p {
          text-align: left;
        }
        a {
          display: inline-block;
          padding: 5px 10px;
          text-align: center;
          text-decoration: none;
          border: 1px solid #2d2b63;
          color: #2d2b63;
          img {
            width: 100%;
            max-width: 20px;
          }
        }
        ul {
          li {
            text-align: left;
          }
        }
      }
    }
  }
  #conta {
    display: block;
    position: relative;
    min-height: 500px;
    .conta-login {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 350px;
    }
  }
  #painel {
    .content-painel {
      display: block;
      width: 100%;
      .head {
        position: relative;
        display: block;
        margin-bottom: 20px;
        min-height: 40px;
        .solicitar {
          float: right;
          padding: 10px 20px;
          background: #5dc3c3;
          color: #fff;
          border-radius: 2px;
          text-decoration: none;
          &:hover {
            background: #3eb6b6;
          }
        }
        .sair {
          float: left;
          padding: 10px 20px;
          background: #5dc3c3;
          color: #fff;
          border-radius: 2px;
          text-decoration: none;
          &:hover {
            background: #3eb6b6;
          }
        }
      }
      .card {
        box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border: solid 1px #f1f1f1;
        border-radius: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin-bottom: 20px;
        @media (max-width: 767px) and (min-width: 501px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 500px) {
          grid-template-columns: 1fr;
        }
        span {
          &.confirm {
            color: #4eb614;
            text-transform: uppercase;
            font-size: 13px;
          }
          &.analise {
            color: #e6996f;
            text-transform: uppercase;
            font-size: 13px;
          }
          &.danger {
            color: #b61414;
            text-transform: uppercase;
            font-size: 13px;
          }
        }
      }
    }
  }
  #solicitar {
    .content-painel {
      display: block;
      width: 100%;
      .head {
        position: relative;
        display: block;
        margin-bottom: 20px;
        min-height: 40px;
        .voltar {
          float: left;
          padding: 10px 20px;
          background: #5dc3c3;
          color: #fff;
          border-radius: 2px;
          text-decoration: none;
          &:hover {
            background: #3eb6b6;
          }
        }
        .solicitar {
          float: right;
          padding: 10px 20px;
          background: #5dc3c3;
          color: #fff;
          border-radius: 2px;
          text-decoration: none;
          &:hover {
            background: #3eb6b6;
          }
        }
      }
      form {
      }
    }
  }
}
.pagination {
  padding: 30px 0;
  display: block;
  text-align: center;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding: 10px 18px;
      color: #666;
      display: inline-block;
      list-style: none;
      &.active {
        a {
          font-weight: 700;
          border-bottom: 3px solid #5dc3c3;
        }
      }
      a {
        outline: 0;
        color: #606060;
        transition: all 0.3s ease 0s;
        text-decoration: none;
      }
    }
  }
}
.remodal {
  padding: 0;
  iframe {
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 605px;
    min-height: 405px;
  }
}

form {
  position: relative;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(black, 0.7);
    z-index: 10;
    i {
      color: white;
      font-size: 70px;
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
    }
    p {
      color: white !important;
      width: 100%;
      text-align: center;
      font-size: 30px;
      margin: 0;
      position: absolute;
      top: calc(50% - 30px);
      left: 0;
      padding: 0 15px;
    }
  }
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99997;

  background-color: white;
  padding: 2rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

  > h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .cookie-consent-content {
    display: flex;
    gap: 1rem;
  }
}

#cookiesModal {
  display: none;
  position: fixed;
  z-index: 99998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-header {
    display: flex !important;
    justify-content: space-between !important;

    &::before,
    &::after {
      content: "";
      display: none;
    }

    h5 {
      font-size: 1.5rem;
    }
  }

  .cookie-types {
    margin-top: 2.5rem;
  }

  h6 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .cookies-modal-buttons-group {
    margin-top: 2rem;
  }
}

#aviso-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  // padding: unset;
  margin-top: auto;
  border-width: 0;

  width: 100vw;
  height: 100vh;

  #content {
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 10033;
    // background: #ffffff;
    padding: 1rem;
    width: 500px;
    height: 400px;
    // background-color: blue;
    border-radius: 25px;
    // border-radius: 50px;
    background: #ffffff;
    box-shadow: -20px 20px 60px #bebebe, 20px -20px 60px #ffffff;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1rem;

    button {
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      z-index: 10033;
      background: #f44336;
      /* background-color: red; */
      font-size: 0;
      border: none;
      padding: 1rem;
      border-radius: 50%;
      // max-width: 1rem;
      // max-height: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
        // z-index: 10034;

        path {
          fill: white;
        }
      }
    }

    #message {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
      // gap: 0.5rem;
      line-height: 2.5rem;
      font-size: 2rem;
      // margin: 1rem;
      padding: 2rem;
      margin-top: 2rem;

      p {
        text-align: justify;
        line-height: 2.5rem;
        font-size: 2rem;
        // :first {
        //   color: red;
        //   font-weight: bold;
        // }
      }
      p:first-child {
        font-weight: bold;
      }
      // span {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 0.5rem;
      // }
    }
    .image {
      position: relative;
      margin: 0 auto;
      height: 60px;
      padding: 1rem;
      a {
        height: 60px;
        img {
          height: 60px;
        }
      }
    }
  }
}
